<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "TextElement",
  mixins: [layoutMixin],
  props: {
    customClass: String,
    customStyle: Object,
    hasImage: Boolean,
    text: String,
    hasHtml: Boolean,
  },
  data() {
    return {
      fontSize: "15px",
    };
  },
  render: function(createElement) {
    this.adaptFont(this.text);

    // text class
    var txtClass = "overflow-hidden overflow-clip flex flex-row";
    if (this.layout) {
      switch (this.layout.align) {
        case 0:
          txtClass += " justify-start text-left";
          break;
        case 1:
          txtClass += " justify-center text-center";
          break;
        case 2:
          txtClass += " justify-end text-right";
          break;
      }
      switch (this.layout.vAlign) {
        case 0:
          txtClass += " items-start";
          break;
        case 1:
          txtClass += " items-center";
          break;
        case 2:
          txtClass += " items-end";
          break;
      }
    }

    if (this.hasHtml) {
      txtClass = "overflow-hidden overflow-clip";
      return createElement("div", {
        domProps: {
          innerHTML: this.text,
        },
        class: [this.layoutClass, this.bgClass, txtClass, this.customClass],
        style: [
          this.layoutStyle,
          this.bgStyle,
          this.textStyle,
          this.customStyle,
        ],
      });
    } else {
      return createElement(
        "div",
        {
          class: [this.layoutClass, this.bgClass, txtClass, this.customClass],
          style: [
            this.layoutStyle,
            this.bgStyle,
            this.textStyle,
            this.customStyle,
          ],
        },
        this.text
      );
    }
  },
};
</script>
